.market-place-offer-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 64px;
  height: 86px;
}

.image-container {
  width: 64px;
  height: 64px;
  border: 2px solid #E6F0E7;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px #E6F0E7;

  .gift-card-logo {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
}

.offer {
  font-weight: 400;
  font-size: 12px;
  margin-top: 6px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .market-place-card-container {
    .offer {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 450px) {
  .market-place-card-container {
    .gift-card-logo {
      width: 70px !important;
      height: 70px !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .market-place-card-container {
    .gift-card-logo {
      width: 62px !important;
      height: 62px !important;
    }
  }
}

@media screen and (max-width: 350px) {
  .market-place-card-container {
    .gift-card-logo {
      width: 55px !important;
      height: 55px !important;
    }
  }
}

@media screen and (max-width: 320px) {
  .market-place-card-container {
    .gift-card-logo {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

@media screen and (max-width: 300px) {
  .market-place-card-container {
    .gift-card-logo {
      width: 50px !important;
      height: 50px !important;
    } 
  }
}