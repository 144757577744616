#basic-detail {
    min-height: 100vh;
    .dark-label {
        font-size: 14px !important;
        margin-bottom: 16px;
    }

    .upload-btn {
        width: 100px;
        height: 35px;
        font-size: 10px !important;
        margin-left: 32px;
        border: 2px solid rgba(94, 212, 151, 0.9);
        color: rgba(94, 212, 151, 0.9) !important;
        background-color: #fff !important;
        padding: initial !important;
    }

    .radio-btn {
        span {
            font-weight: 300 !important;
        }
    }

    .basic-pb-150 {
        padding-bottom: 150px;
    }

}

.facebook-btn-basic-detail:hover {
    background-color: rgba(24, 119, 242, 1) !important;
}

.custom-phone-input{
    padding: 8.5px 14px;
    width: 100% !important;
    height: auto !important;
}

.sub-text {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    letter-spacing: 0.03em !important;
    color: #171717;
}

.font-1-5rem {
    font-size: 1.5rem !important;
}

.sub-text-new {
    font-weight: 700 !important;
    color: #fff;
}
