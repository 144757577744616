$color-black: #171717;

.padding-x {
  padding: 0px 16px !important;
}

.margin-x {
  margin: 0px 16px !important;
}

.margin-y {
  margin: 16px 0px !important;
}

.margin-y-4 {
  margin: 4px 0px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex {
  display: flex;
}

.item-center {
  align-items: center;
}

.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-wrapper {
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 5px 0px;
}

.margin-y-label {
  margin-top: 4px !important;
}

.heading {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 130% !important;
  letter-spacing: -0.01em !important;
  color: $color-black;
}

.section-head {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  letter-spacing: 0.03em !important;
  color: #616161 !important;
  padding: 8px 16px !important;
  background: #eeeeee !important;
}

.sub-text {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  letter-spacing: 0.03em !important;
  color: $color-black;
}

.sub-text-new {
  font-weight: 700 !important;
  color: #fff;
}

.sub-header-icon-container {
  background: #e6f0e7;
  /* padding: 0px 7px; */
  border-radius: 50%;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 47px;
  margin-right: 22px;

  img {
    margin-right: 0px !important;
  }
}

.sub-text-14 {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  letter-spacing: 0.03em !important;
  color: $color-black;
}

.sub-text-16 {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  letter-spacing: 0.03em !important;
  color: $color-black;
}

.sub-header {
  padding: 22px 10px;
  display: flex;
  align-items: center;

  img {
    margin-right: 22px;
  }
}

.custom-input {
  label {
    top: 4px;
  }
}

.text-12 {
  font-size: 12px;
}

.custom-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: $color-black;
  margin-bottom: 4px;
}

.edit-link {
  color: #3d53f5;
}

.custom-btn {
  height: 58px;
  color: #171717 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  letter-spacing: 0.03em !important;
  text-transform: none !important;
  box-shadow: none !important;
  font-family: "Space Grotesk", sans-serif !important;

  &:disabled {
    color: #757575 !important;
  }
}

p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.icon-color {
  fill: #9e9e9e !important;
}

.align-middle {
  vertical-align: middle;
  cursor: pointer;
}

.header-img-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.rounded-top-green {
  background: #219258;
  border-radius: 12px 12px 0px 0px;
}

.circle-img {
  display: block;
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 2px solid #fff;
}

.pill {
  padding: 0.4rem 1.5rem;
  border-radius: 80px;
  cursor: pointer;
  background-color: #eeeeee;
  font-size: 0.8rem;
  font-weight: 700;
  color: grey;
  user-select: none;
}

.pill-active {
  background-color: #171717;
  color: #e6f0e7;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
}

.ml-4 {
  margin-left: 4px;
}

.mx-2 {
  margin: 2px 2px;
}

.edit-new {
  cursor: pointer !important;
  background: #5ed497 !important;
  padding: 4px 8px !important;
  border-radius: 50px !important;
  min-width: 41px !important;
  text-align: center !important;
}

.edit-link-color-new {
  color: #171717 !important;
}

.section-head-bg-light-green {
  background: #e6f0e7 !important;
}

.css-k4qjio-MuiFormHelperText-root.Mui-error {
  margin-left: 0px !important;
}

.text-new {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  letter-spacing: 0.03em !important;
  color: #616161 !important;
}

.text-color-white {
  color: #fff !important;
}
.text-color-black {
  color: #000 !important;
}

.text-primary-color {
  color: #5ed497;
}

.font-32 {
  font-size: 32px;
}

.fw-400 {
  font-weight: 400 !important;
}
.fw-700 {
  font-weight: 700 !important;
}

#app {
  background-color: #fff;
  overflow: hidden;
}

.map-duration-container {
  font-weight: 400;
  pointer-events: none;
  user-select: none;
}

// google maps info box close button
.gm-ui-hover-effect {
  display: none !important;
}

.schedule-title {
  padding: 0px 16px !important;
  margin-bottom: 0px !important;
  font-size: 24px !important;
  justify-content: flex-start !important;
  margin-top: 1rem;
}

.font-16 {
  font-size: 16px !important;
}

.pt-pl-pr-1 {
  padding: 1rem 1rem 0 1rem !important;
}

.text-error {
  color: #d63f3f;
}

#app-container {
  min-height: 100vh;
}

@media screen and (min-width: 800px) {
  #app {
    margin: 0px 25%;
  }

  #mobile-header-1 {
    display: none;
  }

  #mobile-header {
    display: none;
  }
}

@media screen and (max-width: 799px) {
  #web-header {
    display: none;
  }

  .rounded-top-green {
    border-radius: 0px;
  }

  .app-wrapper {
    border-radius: 0px;
  }

  .bg-dialog {
    background-color: #effbf5 !important;
  }
}

@media screen and (max-width: 1023px) {
  .sm-max-100 {
    max-width: 120px;
  }
}

@media screen and (max-width: 390px) {
  .pill, .pill-active {
    font-size: 0.6rem;
  }

  .pill {
    padding: .4rem 1rem;
  }
}

@media screen and (max-width: 320px) {
  .pill {
    padding: .4rem .8rem;
  }
}
