#cancel-trip{
    min-height: 100vh;
    position : relative;
    .done-btn{
        position: absolute;
        bottom: 0;
        padding: 1rem;
        width : 90%
    }
    .done-btn-2{
        width: 90%;
        padding: 1rem;
    }
}
.margin-30{
    margin-top: 30%;
}
@media screen and (min-width: 800px) {
    .done-btn{
        width : 50% !important;
    }
    .margin-30{
        margin-top: 20%;
    }
}