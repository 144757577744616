#payment-cancelled{
    min-height: 75vh;
    // min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: 100px;
    // .cancel_message{
    //     width : 80%;
    // }
}


@media screen and (max-width: 799px) {
     #payment-cancelled{
        min-height: 100vh;
        .cancel_message{
            width : 100%;
        }
     }
}
