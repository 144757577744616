.all-gift-cards{
    img{
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width:800px) {
    .what-next-grouped-gift-cards-container-main{
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
   
}
@media screen and (max-width:800px) {
    .what-next-grouped-gift-cards-container-main{
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .grouped-gift-card-text{
        width:100%;
        box-sizing: border-box;
    }
}
@media screen and (max-width: 350px) {
    .eco-rewards-btn{
        font-size: 14px !important;
        padding: 5px !important;
    }
}