#dashboard-new {
  .main-contents-wrapper {
   // left: 50%;
   // transform: translate(-50%, -50%);
    // min-width: 550px;
    // position: absolute;
    // top: 50%;
    background-color: #f5f5f5;
    border-radius: 16px;
    padding-top: 2rem;
  }
  .primary-color {
    font-weight: 700 !important;
    color: #5ED497 !important;
}
.image-thumbnail {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}
.click {
  cursor: pointer;
}
}
.ecopooling-background{
  position: relative;

  .home-background-image {
    position: absolute;
    margin-top: -2rem;
    border-top: 1px solid #18A957;
  }
}

.invite-alert-container{
  position:absolute;
  width: 45%;
  left: 28%;
  z-index: 10;
}

.dashboard-cards{
    position: relative;
    padding-top: 25%;
    left: 28%;
    width: 45%;
}

@media screen and (max-width:800px) {

  #dashboard-new {
    .main-contents-wrapper {
      padding-top: 0;
    }
  }

  .dashboard-cards{
      left: 5%;
      width: 90%;
      right: 5%;
      padding-top: 54%;
  }

  .invite-alert-container{
    width: 90%;
    left: 5%;
    right: 5%;
    top: 1rem;
  }

  .home-background-image{
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 300px) {
  .dashboard-cards{
    padding-top: 73%;
}
}

@media screen and (max-width: 370px) {
  .invite-alert-container div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
  }
}