#marketplace-how-works {
  .marketplace-how-works-back-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    padding-bottom: 0;
    cursor: pointer;
    gap: 0.5rem;
  }
  .marketplace-how-works-content {
    padding: 0rem 3rem;
    max-width: 16rem;
    margin: 0 auto 10rem;

    .marketplace-how-works-header-container {
      display: flex;
      justify-content: center;
    }
    .marketplace-how-works-line {
      padding: 0.75rem 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      h4 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
  }
}