#pickup-drop {
    background-color: #F5F5F5;

    .location-box {
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        padding: 16px;

        .inline {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .link {
                color: #4BAA79;
                font-size: 14px !important;
                cursor: pointer;
            }

            p {
                font-size: 16px !important;
            }
        }

        .blue-text {
            color: #3D53F5;
            font-weight: 400;
        }
    }

    .next-btn {
        margin-top: 24px;
    }
}

#pickup-drop-drawer {

    margin: 1rem 0.5rem 0 0.5rem;
    .drawer-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        color: #000000;
    }

    .mb-100 {
        margin-bottom: 100px;
    }

    .m-1 {
        margin: 1rem;
    }


    .options-box {
        padding: 16px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .dark-label {
            font-size: 14px !important;
            margin-bottom: 16px;
        }

        .sub-text-10 {
            font-weight: 700 !important;
            font-size: 10px !important;
            line-height: 150% !important;
            color: #171717;
        }

        .day-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 23px;
            padding: 4px 16px;
            background: #EEEEEE;
            border-radius: 100px;
        }

        .active {
            background: #5ED497 !important;
        }

        .save-btn {
            margin-top: 34px;
            margin-bottom: 1rem;
        }
    }

    .css-1xhypcz-MuiStack-root {
        padding-top: 0 !important;
    }
}


@media screen and (max-width: 800px) {
    #pickup-drop-drawer .drawer-title {
        margin-top: 1rem !important;
    }
    
    .schedule-drawer-content {
        height: 100% !important;
    }

    .schedule-later-button {
        margin-top: 2rem !important;
    }

    .next-btn {
        margin-top: auto !important;
    }
}

// to avoid sticking later and next button
@media screen and (max-height: 780px) {
    .next-btn {
        margin-top: 1rem !important;
    }
}