#invite-alerts-schedule-container {
  .flex-2 {
    flex: 2;
  }

  .flex-3 {
    flex: 3;
  }

  .flex-7 {
    flex: 7
  }

  .w-66 {
    width: 66%;
  }

  .w-57 {
    width: 57%;
  }

  .ml-34 {
    margin-left: 34%;
  }
  .invite-alerts-later-container {
    padding: 0 1.5rem;
    display: flex;
    justify-content: center;

    .invite-alerts-later-button {
      height: 58px;
      color: #5ED497 !important;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 150% !important;
      letter-spacing: 0.03em !important;
      text-transform: none !important;
      box-shadow: none !important;
      font-family: "Space Grotesk", sans-serif !important;
      background-color: white !important;
      border: 2px solid #5ED497 !important;
      width: 100%;

      :hover {
        border: 1px solid rgb(94, 212, 151) !important;
        background-color: white !important;
      }
    }

  }
}

@media screen and (min-width: 800px) {
  #invite-alerts-schedule-container {
    .invite-alerts-later-container {
      .invite-alerts-later-button {
        width: 50%;
      }
    }
  }
}