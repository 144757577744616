.dialog-container {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    gap: 5px;

    // .icon {
    //     flex: 2;
    // }

    .text-container {
        flex: 1;
    }
}

.rounded-12 {
    border-radius: 12px;
}

.yellow-background {
    background-color: #FFE4AF;
}

.w-90 {
    width: 90%;
}

.mx-auto {
    margin: auto;
}

.green-background {
    background-color: #E8F6EE;
}

.mt-1rem {
    margin-top: 1rem;
}