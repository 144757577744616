.referral-history{
    font-size: 24px;
    margin-bottom: 0.5rem;
}

.referred-user-image{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    img{
        height: 48px;
        width: 48px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.referred-user-detail-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
}
