#payment_failed{
    min-height: 75vh;
    // min-height: 100vh;
    padding-bottom: 150px;
}


@media  screen and (max-width : 799px) {
    #payment_failed{
        min-height: 100vh;
        .failed_message{
            width : 100%
        }
    }
}
