#custom-detail {
    position: relative;
    height: 100vh;

    .seat-info {
        border-bottom: 1px solid #EEEEEE;
    }

    .text-700 {
        font-weight: 700 !important;
    }

    .primary-color {
        color: #5ED497 !important;
        font-weight: 700 !important;
    }

    .grey-color {
        color: #616161 !important;
        font-weight: 700 !important;
    }

    .MuiSelect-select {
        padding: 13px;
    }

    .count-box {
        border: 2px solid #5ED497;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        cursor: pointer;
    }

    .next-btn-box {
        padding-bottom: 40px;
        padding-top: 20px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid #EEEEEE;
    }
}