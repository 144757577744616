.refer-and-earn-description{
 margin: 0px 29%;
}

.referral-link-container{
    color : #171717
   };

@media screen and (min-width: 800px) {
    .referral-link-container{
     width: 50%;
     margin: 0 auto;
    }
}

@media screen and (max-width: 800px) {
    .refer-and-earn-description{
        margin: 0px;
       }    
}