#invite-alerts-pickup-drop {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    
    .location-box {
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        padding: 16px;

        .inline {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .link {
                color: #4BAA79;
                font-size: 14px !important;
                cursor: pointer;
            }

            p {
                font-size: 16px !important;
            }
        }

        .blue-text {
            color: #3D53F5;
            font-weight: 400;
        }
    }

    .next-btn {
        margin-top: 24px;
    }
    .profile-img{
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
}

#pickup-drop--drawer {
    margin: 0 1rem 250px 1rem;
    .drawer-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        color: #000000;
    }

    .options-pickup-box {
        padding: 16px 16px 175px 16px;

        .dark-label {
            font-size: 14px !important;
            margin-bottom: 16px;
        }

        

        .sub-text-10 {
            font-weight: 700 !important;
            font-size: 10px !important;
            line-height: 150% !important;
            color: #171717;
        }

        .day-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 23px;
            padding: 4px 16px;
            background: #EEEEEE;
            border-radius: 100px;
        }

        .active {
            background: #5ED497 !important;
        }

        .save-btn{
            margin-top: 34px;
        }
    }
    .css-1xhypcz-MuiStack-root{
        padding-top: 0 !important;
    }
}

@media screen and (max-width: 800px) {
    #invite-alerts-pickup-drop {
        min-height: 100vh;
    }
    
}