#get-verified-screen{
    background: #0A2014;
    min-height: 100vh;

    .get-verified-testimonial-item{    margin-bottom: 0px;
        border : 1px solid #5ED497;
        background: #fff;
        padding: 0.5rem;
        border-radius: 12px;
    }

}

@media screen and (min-width: 800px) {
    #get-verified-screen{
        min-height: 90vh !important;
    }
}
