#home-location {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .next-btn {
        margin-top: 24px;
    }
}

@media screen and (max-width: 800px) {
    #home-location {
        min-height: 100vh;
    }
}