.eco-rewards-wrapper {
  .heading-title {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    // height: 31px;
    .search-box {
      border: 2px solid #E0E0E0;
      white-space: nowrap;
      border-radius: 5px;
      /* background-color: #F5F5F5; */
      display: flex;
      align-items: center;
      /* width: 350px; */
      // height: 48px;
      width: 100%;
      padding: 8px 12px;

      .search-input {
        background: transparent;
        border: navajowhite;
        width: calc(100% - 30px);
        font-size: 0.9rem;

        &:focus {
          outline: none;
        }
      }

      .search-icon {
        padding: 3px 4px;
        width: 40px !important;
        height: 25px !important;
        color: gray;
      }
    }

    .eco-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 31.2px;
      letter-spacing: -0.01em;
    }
  }

  .eco-rewards-marketplace {
    // margin: 16px auto;
    padding: 20px 48px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    .eco-token-balance-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .token-balance-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    // height: 121px;
    background: #effbf5;
    border: 1px solid #5ed497;
    border-radius: 20px;
    // width: 50%;
    padding: 1rem 2rem;
    margin-bottom: 1rem;

    .loyalty-card {
      margin-left: 16px;
      margin-top: 16px;

      .card-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 265px;
        height: 89px;

        .card-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 8px;
          width: 157px;
          height: 24px;

          .token-header {
            width: 125px;
            height: 21px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            text-align: center;
            color: #000000;
          }
        }

        .token-count {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          padding: 0px;
          gap: 4px;
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          width: 90px;
          height: 31px;

          .count {
            font-weight: 400;
            font-size: 12px;
          }
        }

        .token-value {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
        }
      }
    }
  }

  .pill-bar-main-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    padding: 0 1rem 1rem;
    flex-wrap: wrap;

    .gift-card {
      border-radius: 100px;
      line-height: 150%;
      padding: 4px 16px;
      gap: 10px;
      cursor: pointer;
      width: 99px;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .active-btn {
      background: #4baa79;
      font-weight: 700;
      color: #fcfcfc;
      font-size: 14px;
    }

    .active-btn-black-marketplace {
      background: #171717;
      font-weight: 700;
      color: #fff;
      font-size: 14px;
    }

    ;

    .disable-btn {
      background: #eeeeee;
      font-style: normal;
      font-weight: 490;
      font-size: 14px;
      color: #616161;
    }

    ;

    .purchase-history {
      display: flex;
      align-items: center;
      padding: 4px 16px;
      gap: 10px;
      width: 147px;
      height: 29px;
      cursor: pointer;
      border-radius: 100px;
      line-height: 21px;
      align-items: center;
      justify-content: center;
    }
  }

}

.position-relative {
  position: relative;
}

.disable-click-container {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}

.gift-card-title {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  letter-spacing: 0.03em !important;
  color: #171717 !important;
  padding: 8px 16px !important;
  background: #eee !important;
}

.market-place-card-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 15px;
  flex-wrap: wrap;
  padding: 16px;
  column-gap: 0px;
}

.drawer-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
  padding-bottom: 16px;

  .list-box {
    border-bottom: 1px solid #EEEEEE;
    padding: 8px 0px;
    display: flex;
    align-items: center;

    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;
      min-width: 56px;
      border-radius: 12px;
      background: #5ed497;
      box-shadow: 2px 2px 4px 0px #E6F0E7;
      border: 2px solid #EFFBF5;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      padding: 4px;
      margin-left: 16px;
      flex: 1;
      // width: 70%;
    }

    .price {
      display: flex;
      gap: 10px;
      align-items: center;
      text-align: right;

      .amount {
        display: inline-block;
        min-width: 40px
      }

      .right-arrow-icon {
        display: inline-block;
      }
    }
  }

  .list-box:last-child {
    border-bottom: none;
  }
}

.other-amount-wrapper {
  margin-top: 16px;
  margin-bottom: 16px;

  .other-amount-title {
    // margin-left: 16px;
    display: flex;
    justify-content: center;
  }

  .inner-wrapper {
    display: flex;
    flex-direction: column;

    .form-box {
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      width: 100%;

      .w-90 {
        width: 90%;
      }
    }

    .price-entered {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 16px;

      .right-arrow-icon {
        display: inline-block;

        .btn-width {
          width: 100%!important;
        }

        .other-amount-arrow-button {
          cursor: pointer;
        }

        .other-amount-arrow-button-disabled {
          opacity: 0.5;
          pointer-events: 'none'
        }
      }
    }
  }
}

.purchase-history-drawer-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.puchase-history-pb-150 {
  padding-bottom: 150px;
}

.marketplace-video-frame {
  width: 50%;
  height: 50%;
}

@media screen and (max-width: 1200px) {
  .market-place-card-container {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (max-width: 1140px) {
  .market-place-card-container {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 980px) {
  .market-place-card-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 830px) {
  .market-place-card-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 800px) {
  .gift-card-container {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 800px) {
  .other-amount-wrapper {
    margin-bottom: 16px;

    .right-arrow-icon {
      width: 92%
    }
  }

  .market-place-card-container {
    grid-template-columns: repeat(9, 1fr);
  }

  .marketplace-video-frame {
    width: 90%;
  }
}

@media screen and (max-width: 730px) {
  .market-place-card-container {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media screen and (max-width: 650px) {
  .market-place-card-container {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (max-width: 570px) {
  .market-place-card-container {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 490px) {
  .market-place-card-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 450px) {
  .market-place-card-container {
    grid-template-columns: repeat(3, 1fr);

    .image-container {
      width: 104px;
      height: 98px;
    }
  }
}

@media screen and (max-width: 400px) {
  .market-place-card-container {
    grid-template-columns: repeat(3, 1fr);

    .image-container {
      width: 90px;
      height: 85px;
    }
  }
}

@media screen and (max-width: 350px) {
  .market-place-card-container {
    grid-template-columns: repeat(3, 1fr);

    .image-container {
      width: 80px;
      height: 75px;
    }
  }
}

@media screen and (max-width: 320px) {
  .market-place-card-container {
    grid-template-columns: repeat(3, 1fr);

    .image-container {
      width: 75px;
      height: 70px;
    }
  }
}

@media screen and (max-width: 300px) {
  .market-place-card-container {
    grid-template-columns: repeat(3, 1fr);

    .image-container {
      width: 72px;
      height: 68px;
    }
  }
}

.no-purchase-history-container {
  padding: 2rem;
}

#no-purchase-history-card {
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  padding: 1rem;
  gap: 1rem;
  border: 2px solid #efefef;
  
  h4 {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .no-purchase-history-description-container {
    flex: 10;
  }
}