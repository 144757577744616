// .background-container{
//     background: url("/assets/images/background.png");
//     height: 500px;
//     background-repeat: no-repeat;
//     background-size: cover;
//     position: relative;
// }

// .app-wrapper-with-background{
//     position: absolute;
//     top:55%;
//     width: 50%; 
//     left : 28%;   
// }

// @media screen and (max-width:800px) {
//     .background-container{
//         background-size: contain;
//     }
// }

.main-outer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-height: 100vh;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 4rem;
    background-color: #fff;
    margin-top: auto;
    border-top: 1px solid #18A957;
}

.footer-logo {
    margin-bottom: 1.5rem;
}

.footer-nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    // padding-bottom: 1.5rem;
    // margin-bottom: 1.5rem;
    width: 100%;
    // border-bottom: 1px solid #eee;
}

.footer-nav {
    list-style: none;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.app-body-wrapper {
    margin-bottom: 2rem !important;
}

@media screen and (max-width: 800px) {
    .main-outer-wrapper {
        gap: 0;
    }

    .app-wrapper {
        flex-grow: 1;
    }

    .footer-nav {
        flex-direction: column;
    }

    .app-body-wrapper {
        margin-bottom: 0rem !important;
    }
}