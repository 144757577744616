.no-trips-container {
  display: flex;
  flex-direction: row;  
  .no-card-description {
    flex: 14;
    margin-left: 1rem;
    .h3 {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-size: 16px;
    }
    .p {
      font-size: 14px!important;
    }
  }

}

.my-3rem {
  margin: 3rem auto;
}

.rounded-1 {
  border-radius: 0.5rem;
}
