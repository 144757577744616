#booking-confirm{
    // height: 100vh;
    position : relative;
    min-height: 75vh;
    padding-bottom: 100px;

    .done-btn{
        // position: absolute;
        bottom: 0;
        padding: 1rem;
        width : 90%
    }
}

@media screen and (min-width: 800px) {
    .done-btn{
        position: static !important;
        width : 50% !important;
    }
    .confirm-seat-info-list{
        width: 70% !important;
    }
}

@media screen and (max-width: 800px) {
    #booking-confirm{
        min-height: 100vh;
    }
}