.font-700 {
  font-weight: 700 !important;
}

#trip-detail {
  position: relative;
  min-height: 83vh;
  background-color: rgba(239, 251, 245, 1);

  .next-btn-box {
    padding-bottom: 40px;
    padding-top: 20px;
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #EEEEEE;
  }
}

@media screen and (max-width: 800px) {
  #trip-detail {
    min-height: 99vh;
  }
}
