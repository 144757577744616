#schedule-detail {
    .location-box {
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        padding: 16px;

        .inline {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .link {
                color: #4BAA79;
                font-size: 14px !important;
                cursor: pointer;
            }

            p {
                font-size: 16px !important;
            }
        }

        .blue-text {
            color: #3D53F5;
            font-weight: 400;
        }
        .red-text {
            color: #E95C7B;
            font-weight: 400;
        }
    }

    // @media screen and (max-width: 800px) {
    //     .locationsContainer {
    //         min-height: 78vh;
    //     }
    // }


    .next-btn {
        margin-top: 24px;
    }
}

#favorite-location-drawer {
    height: 85vh;

    .search-box {
        margin-top: 10px;
        display: flex;
        align-items: center;

        .cancel {
            margin-left: 16px;
            color: #3D53F5;
        }
    }

    .selected {
        margin-top: 18px;
        display: flex;
        align-items: center;

        .fw-700 {
            font-weight: 700 !important;
        }

        .sub-text {
            color: #616161;
            margin-left: 10px;
        }
    }

    .show-locations {
        .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 130%;
            color: #000000;
            margin-bottom: 16px;
            margin-top: 24px;
        }

        .MuiMenuItem-root {
            padding: 0px !important;
        }

        .location-box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .sub-text {
                font-size: 16px !important;
            }

            .location-name {
                margin-left: 12px;
            }

            .distance {
                color: #616161;
                margin-left: 90px;
            }


        }
    }

    .active-location {
        color: #4BAA79;
        cursor: pointer;
    }
}

@media screen and (min-width: 1024px) {
    #favorite-location-drawer {
        height: fit-content;
        min-height: 300px;
    }
}
