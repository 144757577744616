#invite-marketplace-container {
  .invite-friends-content {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;

    .invite-friends-image-container {
      padding: 0.5rem 0 1rem 0;
      width: 100%;

      img {
        // object-fit: contain;
        width: 100%;
        // height: 16rem;
      }
    }

    .invite-friends-list-container {
      padding: 0 1rem;

      .list-line {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 1rem 0;
        // justify-content: space-between;
      }
    }

    .invite-friends-link-container {
      width: 100%;
      padding: 1rem;
      font-weight: bold;

      .invite-friends-link {
        .overflow-x {

          overflow-x: auto;
        }

        border: 1px dashed;
        border-radius: 1rem;
        align-items: center;
        padding: 1rem;
        color: #3c3fff;
        background-color: #d8ddfd;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .invite-friends-fixed-button-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.2rem;
      color: #5ED497;
      cursor: pointer;

      .invite-friends-rewards-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding: 1rem 0;
        border: 1px solid #5ED497;

        img {
          padding: 0 0 0 0.5rem;
        }
      }
    
  }
}

@media screen and (max-width: 800px) {
  #invite-marketplace-container {
    .invite-friends-fixed-button-container {
      .invite-friends-rewards-button {
        width: 100%!important;
      }
    }
    .invite-friends-list-container {
      font-size: 14px;
    }
  }
}

