.what-next-header-icon-container {

    width: 80px;
    height: 80px;

    .what-next-header-icon {
        height: 100%;
        width: 100%;
    }
    
}

@media screen and (min-width:800px) {
    .matched-result{
         // margin: 0 25%;
          width: 80%;
    }
}
@media screen and (max-width:800px) {
    .matched-result{
        width: 80%;
    }

    .what-next-whatsapp-button{
        width: -webkit-fill-available !important;
    }
}
