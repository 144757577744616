#matches-offer {
    position: relative;
    min-height: 100vh;
    padding-bottom: 100px;

    .sub-header {
        padding: 22px 0px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
    }

    .matches-box {
        min-height: 73vh;
        overflow: auto;
        background-color: #FFF;
        padding: 0 16px 100px 16px;
    }

    .primary-color {
        font-weight: 700 !important;
        color: #5ED497 !important;
    }

    .click {
        cursor: pointer;
    }

    .trip-person-info {
        min-height: 237px;
        border: 2px solid #616161;
        border-radius: 20px;
        margin-bottom: 24px;
        padding: 16px;

        .view {
            cursor: pointer;
            display: inline-block;
        }

        .driver-name {
            font-weight: 700 !important;
        }

        .verified-box {
            padding: 2px 4px;
            width: 63px;
            height: 22px;
            background: #EFFBF5;
            border-radius: 50px;
        }

        .invite-btn {
            min-width: 69px;
            height: 28px;
            background: #EEEEEE;
            color: #616161;
            border-radius: 50px;
            padding: 0px 4px;

            img {
                margin-right: 5px;
                display: none;
            }
        }

        .invite-btn-active {
            border: 1px solid #eee !important;
            // background: #EFFBF5 !important;
            color: #171717 !important;
            font-weight: 500;
            
            img {
                display: initial;
            }
        }
        
        .invite-active {
            color: #111 !important;
            background-color: #5ED497 !important;
            border: 1px solid #5ED497 !important;
        }
    }

    .invited-person {
        border: 2px solid #5ED497 !important;
    }

    .map-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .expand-transition {
        transition: all 0.15s linear;
    }

    .text-faded {
        color: #616161;
    }

    .text-600 {
        font-weight: 600 !important;
    }

    .items-center {
        align-items: center;
    }

    .primary2-color {
        color: #11763D;
    }

    .rounded {
        border-radius: 0.5rem;
    }

    .next-btn-box {
        padding-bottom: 40px;
        padding-top: 20px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid #EEEEEE;
    }

    .seat-unavailable-message-box {
        border-radius: 20px;
        background: #fff;
    }

    @media screen and (max-width: 800px) {
        .matches-box {
            min-height: 81vh;
        }

        .seat-unavailable-message-box {
            margin-left: 1rem;
            margin-right: 1rem;
            border-radius: 20px;
        }
    }
}

.filter-button {
    border-radius: 10px !important;
    box-shadow: none !important;
    font-weight: 700 !important;
    color: #999 !important;
    background-color: #efefef !important;
    font-size: 12px !important;
    padding: .4rem .6rem;
    transition: all 0.15s linear;
    user-select: none;
}

.filter-button:hover,
.selected-filter {
    background-color: #E8F6EE !important;
    color: #18A957 !important;
}

.seat-unavailable {
    display: flex;
    justify-content: center;
    text-align: center;
}

.offer-carpool-wrapper {
    min-height: 84vh;
    //background: #EFFBF5;
}

.uninvited-user {
    border: 2px solid #616161 !important;
}

@media screen and (max-width: 800px) {
    .offer-carpool-wrapper {
        min-height: 100vh;
    }
}
