.rel-box{
    position: relative;
    width: 50px;

    #img-top{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 48px;
        max-height: 48px;
        object-fit: cover;
    }

    #img-bottom{
        position: absolute;
        top: 50%;
        left:70%;
        transform: translate(-50%,-50%);
        max-width: 48px;
        max-height: 48px;
        object-fit: cover;
    }

    #info-icon{
        position: absolute;
        display: grid;
        place-items: center;
        padding: 2px;
        color: #171717;
        font-size: 9px;
        font-weight: 700;
        background-color: #5ED497;
        border-radius: 50%;
        bottom: -23px;
        right: -5px;
    }
}