#marketplace-login {

  .marketplace-body-container {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 12rem;

    .marketplace-login-pic {
      object-fit: fill;
      width: 100%;
      aspect-ratio: 10/6;
    }

    .marketplace-privacy-text {
      font-size: 14px;

      .marketplace-login-link {
        color: #4BAA79;
      }
    }
  }
}