.payment-failed-wrapper-main{
    padding: 24px;
    .sub-payment-failed-wrapper{
        padding: 16px;
        display: flex;
        flex-direction: column;
        text-align: center;
        height: calc(100vh - 290px);
        margin: 20% auto;  
    }
}
.failed-text{
    font-weight: 700;
}
