#share-detail {

    .share-benefits {

        .sub-text-16 {
            font-weight: 700 !important;
        }

        ul {
            margin-block-start: 8px;
            margin-block-end: 15px;
            padding-inline-start: 25px;
        }
    }

    .referral {
        .sub-text-14 {
            color: #616161 !important;
            margin-bottom: 4px;
        }

        .copy-code {
            cursor: pointer;
            height: 20px;
            padding: 9.5px 16px;
            background: #EFFBF5;
            border: 1px dashed #5ED497;
            border-radius: 12px;
            justify-content: space-between !important;

            p {
                font-weight: 700 !important;
                color: #5ED497 !important;
            }
        }
    }

    .next-btn{
        img{
            margin-right: 5px;
        }
    }
}