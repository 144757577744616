.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    .MuiCheckbox-root{
        padding: 0px;
    }

    p{
        margin-left: 11px;
        font-size: 16px!important;
        gap: 16px;
    }
}

#profile {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 800px) {
    #profile {
        min-height: 100vh;
    }
    
    .update-vehicle-button-wrapper {
        flex: 1;
    }
}