#marketplace-friends-list {
    .marketplace-friends-list-content {
        padding: 1.5rem;

        .marketplace-friends-list-container {
            .friend-container {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 1rem 0;
                small {
                    color: #616161;
                }
            }

            .border-top {
                border-top: 1px solid #eee;
            }

        }


    }
}