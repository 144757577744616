#gift-provider {
    .gift-bg {
        background-color: #F5F5F5;
        min-height: calc(100vh - 251px);
    }

    .gift-box {
        margin: 12px 0px;
        padding: 24px;
        width: 33%;
        height: 120px;
        background: #FCFCFC;
        border: 1px solid rgba(48, 49, 51, 0.08);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 120px;
            height: 72px;
            margin-bottom: 19px;
        }
    }

    .active {
        background: #EFFBF5;
        border: 2px solid #4BAA79;
    }

    .next-btn {
        margin: 36px 0px;
    }
}