.no-match{
    height:82vh;
}

.next-btn-box {
    padding-bottom: 40px;
    padding-top: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #EEEEEE;
}