#custom-drawer {
    .MuiDrawer-paper {
        border-radius: 20px 20px 0px 0px;
        // max-height: 75%;
    }


    @media screen and (min-width: 800px) {
        .MuiDrawer-paper {
            margin: 0% 31%;
        }
    }

    .pillar {
        width: 77px;
        height: 6px;
        background: #fff;
        border-radius: 6px;
    }

    .show-lg {
        display: none;
    }

    .show-sm {
        display: none;
    }

    .bg-w {
        background-color: white;
        max-height: 600px;
        border: none;
        width: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        outline: none;
        border-radius: 8px;
        max-height: 80%;
    }

}

.drawer-close-icon {
    right: 1rem;
    top: .6rem;
}

.drawer-back-icon {
    left: 1rem;
    top: .6rem;
    align-items: center;
    display: flex;
}

#custom-drawer-full-screen {
    .MuiDrawer-paper {
       height: 100%;
    }

    @media screen and (min-width: 800px) {
        .MuiDrawer-paper {
            margin: 0% 31%;
        }
    }

    .pillar {
        width: 77px;
        height: 6px;
        background: #E0E0E0;
        border-radius: 6px;
    }

    .show-lg {
        display: none;
    }

    .show-sm {
        display: none;
    }

    .bg-w {
        background-color: white;
        max-height: 600px;
        border: none;
        width: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        outline: none;
        border-radius: 8px;
        max-height: 80%;
    }

}

.height-75vh {
    max-height: 75vh;
}

@media screen and (max-height:650px) {
    .sm-h-80{
        max-height: 80% !important;
    }
}
