.signup-footer-text{
    padding: 0 1rem 1rem 1rem;
    .footer-text-link-color{
        color: #4BAA79;
    }
}

.mt-auto{
    margin-top: auto;
}

.text-sm-center{
    text-align: center;
    margin : 0px 25%;
    @media (max-width: 800px) {
        margin: 0
    }
}