.back-to-whatsapp-button-container {
    padding: 2rem 1rem 2rem 1rem;
    background-color: white;

    .custom-back-to-whatsapp-button {
        height: 58px;
        // color: rgb(94, 212, 151) !important;
        color: black !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 150% !important;
        letter-spacing: 0.03em !important;
        text-transform: none !important;
        box-shadow: none !important;
        font-family: "Space Grotesk", sans-serif !important;
        background-color: white!important;
        border: 2px solid #5ED497!important;
        width: 100%;
    
        // :hover {
        //   border: 2px solid rgb(94, 212, 151)!important;
        //   background-color: white!important;
        // }
      }
}

.horizontal-margin {
  margin: 0 25%;
}


@media screen and (max-width: 800px) {
    .back-to-whatsapp-button-container {
        margin: 0;
        // width: 100%;
        padding: 1.5rem;
        // .custom-back-to-whatsapp-button {
        
        // }
    }
}