#upcoming-detail {

    // background: #F5F5F5;
    background: white;

    .primary-color {
        font-weight: 700 !important;
        color: #5ED497 !important;
    }

    .image-thumbnail {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        object-fit: cover;
    }

    .click {
        cursor: pointer;
    }

    .height-100 {
        margin: 20px 0px;
    }

    .driver-name {
        font-weight: 700 !important;
    }

    .verified-box {
        padding: 2px 4px;
        width: 63px;
        height: 22px;
        background: #EFFBF5;
        border-radius: 50px;
    }

    .add-location-btn {
        margin-top: 16px;
        border: 2px solid #4BAA79 !important;
        color: #4BAA79
    }

    .link-style-none {
        text-decoration: none;
        color: #5ED497 !important;
    }
    .link-style-new {
        text-decoration: none;
        color: #171717 !important;
    }

    .custom-input {
        border: 1px solid #E0E0E0;
        border-radius: 3px;
    }

}

.text-700 {
    font-weight: 700 !important;
}

.list-container {
    height: 56px;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 16px;
}

.gap-12 {
    gap: 12px;
}

.last-list-container {
    border-bottom: none;
}

.option-box-title {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 700;
}

.option-box-helper-text {
    font-size: 12px;
    margin: 10px 0px;
    line-height: 1.2;
}

.green{
    background-color: #E8F6EE !important;
}

.yellow{
    background-color: #FFF8EB !important;
}

.red{
    background-color: #FCE8EC !important;
}

.gray-trip-status {
    border: 1px solid #9E9E9E;
}

.red-trip-status {
    border: 1px solid #DF1642;
}

.red-trip-status > div {
    color: #DF1642 !important;
}

.trip-text-light-gray {
    color: #616161 !important;
}

.p-4{
    padding: 4px 8px 4px 4px;
    border-radius: 12px;
}

#pickup-drop-sec {
    .location-box {
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        padding: 16px;

        .inline {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .link {
                color: #4BAA79;
                font-size: 14px !important;
                cursor: pointer;
            }

            p {
                font-size: 16px !important;
            }
        }

        .blue-text {
            color: #3D53F5;
            font-weight: 400;
        }
    }

    .next-btn {
        margin-top: 24px;
    }

    .profile-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }

}

.pill-bar-wrapper{
    display: flex;
}
