#profile {
    padding-bottom: 150px;
    min-height: 100vh;
    .link {
        color: #3D53F5;
        cursor: pointer;
    }

    .your-detail {
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-bottom: 10px;
        }

        .name {
            font-weight: 700 !important;
        }
    }

    .mb-10{
        margin-bottom: 20px;
    }

    .location-box {
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        padding: 16px;

        img {
            margin-right: 16px;
        }

        .inline {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .link {
                color: #4BAA79;
                font-size: 14px !important;
                cursor: pointer;
            }

            p {
                font-size: 16px !important;
            }
        }

        .blue-text {
            color: #3D53F5 !important;
            font-weight: 400;
        }

        .dark-text {
            color: #000000 !important;
        }
    }
}