.header-box {
    padding: 30px 18px 25px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EEEEEE;
    background-color: #FFF;
    // width:393px;
    // height:104px;

    button {
        border: 2px solid #5ED497;

        &:hover {
            border: 2px solid #5ED497;
        }
    }
}

.header-box-with-back {
    padding: 10px 18px 25px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EEEEEE;
    background-color: #FFF;
    // width:393px;
    // height:104px;

    // button {
    //     border: 2px solid #5ED497;

    //     &:hover {
    //         border: 2px solid #5ED497;
    //     }
    // }
}

.parent {
    .t-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.back-arrow {
    padding: 20px 18px 5px 18px;
    cursor: pointer;
}

.header-profile-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
