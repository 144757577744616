.gift-card-container {
    min-height: 70vh;
    position: relative;

    iframe {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: none;
        border-radius: 6px;
        min-height: 70vh;
        // display: inline-block;
    }
}