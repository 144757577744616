.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  flex-direction: column;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  height: 100% !important;
  overflow: auto !important;

  -webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
   -ms-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
}
