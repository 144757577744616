#eligibility-success {
  background: #0a2014;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
}

@media screen and (max-width:800px) {
  #eligibility-success{
    min-height: 100vh !important;
  }
}
