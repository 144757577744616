.access-check-code-button {
    border: 1.5px solid #5ED497;

}

.driver-verification-wrapper {
    padding: 1rem 1rem 150px 1rem;
}

@media screen and (min-width: 800px) {
    .driver-verification-wrapper {
        min-height: 85vh !important;
    }
}
