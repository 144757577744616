#web-header {
    transition: all 0.5s;
  //  margin: 0 2.5% 1rem;
  

    .header-box {
        padding: 21px 50px 15px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #EEEEEE;
        background-color: #FFF;
        // width:393px;
        // height:104px;

        button {
            border: 2px solid #5ED497;

            &:hover {
                border: 2px solid #5ED497;
            }
        }
    }

    .header-box-with-back {
       // padding: 30px 50px 15px 50px;
        padding:30px 80px 30px 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #EEEEEE;
        // border-bottom: 1px solid #18A957;
        background-color: #FFF;
    }

    .parent {
        .t-img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }

    .back-arrow {
        padding: 20px 18px 5px 18px;
        cursor: pointer;
    }

    .header-profile-image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
    }

    .sub-header {
       // background-color: #4BAA79;
       background: linear-gradient(to left, #3ABA78 0%, #1E6B43 100%);
        margin: 0px;
       // padding: 20px 50px;
        padding: 20px 100px;

        .link {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            color: #fff;
            margin-right: 24px;
        }
    }
}

.my-upcoming-trips-button{
    border: 2px solid #5ED497 !important;
    margin-right: 8px !important; 
}


.hide-header {
    display: none !important;
}
