#declaration {
    .read-instruction {
        margin-top: 16px;
        margin-bottom: 24px;
        background: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0px 16px 16px;
    }
    .max-height-300{
        max-height: 300px;
        overflow: auto;
    }

}

.declaration-drawer {   
    .confirm-text {
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        color: #000000;
    }

    .options-box {
        padding: 16px;
    }

    .dark-label {
        font-size: 14px !important;
        margin-bottom: 16px;
    }

    .checkbox-container {
        display: flex;
        align-items: center;
        margin-bottom: 22px;

        .MuiCheckbox-root{
            padding: 0px;
        }

        p{
            margin-left: 11px;
            font-size: 16px!important;
            gap: 16px;
        }
    }

    .confirm-btn {
        margin-top: 31px;
    }
}