// .eligibility-check-process-description-container{
//     margin: 0px 25%;
// };

.emailInputBox {
    margin-top: 1rem;
}

@media screen and (max-width: 800px) {
    .eligibility-check-main-wrapper {
        min-height: 92vh;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    
    .emailInputBox {
        margin-top: auto;
    }
}


@media screen and (max-width:800px) {
    .eligibility-check-process-description-container{
        margin: 0px;
    };
}

.eligibility-check-main-wrapper {
    padding-bottom: 150px;
}