.user-verified-wrapper-main{
    // padding: 24px;
    min-height: 80vh;
    // display: grid;
    // grid-template-rows: 1fr auto;
    // justify-content: center;
    // align-items: center;
    .user-verified-wrapper{
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .sub-user-verified-wrapper{
        padding: 16px;
    }
    .verified-user-name{
        gap: 16px;
        padding: 8px 16px;
    }
    .verified-user-address{
        gap: 16px;
        padding: 8px 16px;
    }
}
.success-text{
    font-weight: 700;
}

@media screen and (max-width:800px) {
    .user-verified-wrapper-main{
        height: 100vh;
    }
}