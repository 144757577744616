.group-tab-container {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  cursor: pointer;

  .group-tab-active {
    border: 1px solid #82DEAE;
    background-color: #EFFBF5;
    padding: 1.5rem;
    border-radius: 14px;
    width: 100%;
  }

  .group-tab-inactive {
    border: 1px solid #EAEAEA;
    background-color: #FCFCFC;
    padding: 1.5rem;
    border-radius: 14px;
    width: 100%;
  }
}

.group-no-data-found {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.group-card {
  font-size: 14px;
  border: 1px solid #5ED497;
  border-radius: 1rem;
  padding: 0.75rem;
  margin: 2rem 0;

  .p-75 {
    padding: 0 0.75rem;
    color: #E0E0E0;
  }

  .group-card-header {
    display: flex;
    justify-content: space-between;

    .group-card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .user-img-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      // padding-left: 0.75rem;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .group-card-body {
    .line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5rem 0;

      p {
        padding-left: 1rem;
      }
    }
  }

  .group-card-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 1rem 0.5rem;
    border-radius: 5px;
  }

  .group-map-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #5ED497;
    // padding: 0.5rem;
  }
}