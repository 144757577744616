#car-detail {
    min-height: 100vh;
    padding-bottom: 50px;
    .next-btn {
        margin-top: 24px;
    }

    .MuiAutocomplete-inputRoot {
        height: 48px;
    }

    .MuiSelect-select {
        padding: 13px;
    }

    .align-middle {
        vertical-align: middle;
        cursor: pointer;
    }

    .vehicle-detail-input input::placeholder {
        font-size: 14px;
        color: #000;
    }

    @media screen and (max-width: 800px) {
        min-height: 97vh;
    }
}
