
@media screen and (max-width: 800px) {
    .verification-failed-wrapper-main {
        min-height: 100vh;
    }   
}

.verification-failed-wrapper-main {
    padding: 150px;
}   
