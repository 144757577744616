.fullWidth{
    width: 100%;
    display: block;
}

.fullWidth > div:not(.set-time-overlay) {
    padding: 0 !important;
}

.set-time-overlay {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
    border: 1px solid #cecece;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: 16px;
    cursor: default;
    user-select: none;
    color: #9e9e9e;
    padding-left: 14px !important;
}

@media screen and (max-width: 397px) {
  .set-time-overlay {
      font-size: 14px;
  }
}