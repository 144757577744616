#private-community {

    .private-community-main-wrapper {
        margin: 1rem 0 1rem 0;

        .private-community-signup {
            padding-bottom: 1rem
        }
    }

    .content-lower{
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);
    }

}
