.fixed-button-container {
  position: fixed;
  bottom: 0;
  background-color: white;
  left: 0;
  right: 0;
  padding: 1rem 1.5rem 1.75rem 1.5rem;
  z-index: 2;

  .border-t {
    border-top: 1px solid #eeeeee;
  }

  .custom-button {
    height: 58px;
    color: #171717 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: 0.03em !important;
    text-transform: none !important;
    box-shadow: none !important;
    font-family: "Space Grotesk", sans-serif !important;
    // width: 50%;
  }

  .custom-upper-button {
    height: 58px;
    color: rgb(94, 212, 151) !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: 0.03em !important;
    text-transform: none !important;
    box-shadow: none !important;
    font-family: "Space Grotesk", sans-serif !important;
    background-color: white!important;
    border: 1px solid rgb(94, 212, 151, 0.5)!important;
    // width: 50%;

    :hover {
      border: 1px solid rgb(94, 212, 151)!important;
      background-color: white!important;
    }
  }

  .button-container-width {
    width: 50%;
    padding-bottom: 1rem;
  }

  .mx-auto {
    margin: auto;
  }
  .drawer-button-container-width {
    width: 100%;
    padding-bottom: 1rem;
  }
  .btn-width-full {
    width: 100%;
  }
}


.mx-25-desktop-without-drawer {

  margin: 0 25%;
}


@media screen and (max-width: 800px) {
  .mx-25-desktop-without-drawer {
    margin: 0;
  }
  .fixed-button-container {

    .button-container-width {
      width: 100%;
    }
    .custom-button {
      width: 100%;
    }
    .custom-upper-button {
      width: 100%;
    }
  }
}