#earnings {
  .week-card {
    .week-card-header {
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 150% !important;
      letter-spacing: 0.03em !important;
      color: #171717 !important;
      padding: 8px 16px !important;
      background: #eee !important;
    }
    .week-card-body {
      padding: 1rem;

      .trip-rewards-card {
        display: flex;
        // justify-content: space-between;
        align-items: center;

        .trip-rewards-fuel-icon {
          flex: 1;
          display: flex;
          justify-content: center;
        }

        .trip-rewards-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex: 10;
          padding: 1rem 0;

          .trip-rewards-left-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            gap: 0.5rem;

            p {
              font-size: 14px;
            }
          }
          
          .trip-rewards-right-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            gap: 0.5rem;

            p {
              font-size: 14px;
            }

            b {
              text-align: end;
            }
          }
        }

        .trip-rewards-chevron {
          flex: 1;
          display: flex;
          justify-content: center;
          cursor: pointer;
        }
      }

      .horizontal-line-color {
        background-color: #eeeeee;
        height: 1px;
        border: none;
      }
    }
  }
  .week-card-load-more-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 1rem 0;

    .week-card-load-more-button {
      height: 58px;
      color: #171717 !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 150% !important;
      letter-spacing: 0.03em !important;
      text-transform: none !important;
      box-shadow: none !important;
      font-family: "Space Grotesk", sans-serif !important;
      width: 50%;
    }
  }
  .earnings-no-data-found {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    color: #65799C;
  }
}

@media screen and (max-width: 800px) {
  #earnings {
    .week-card-load-more-container {
      .week-card-load-more-button {
        width: 90%;
      }
    }
  }
}