#get-check-code{
    min-height: 100vh;
    .label-container {
        font-weight: 700;
        padding-bottom: 4px;
      }
      input {
        outline: none;
        border-radius: 4px;
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        border: 2px solid #e0e0e0;
      }
}

@media screen and (min-width: 801px) {
    .get-check-code-main{
        display: flex;
        flex-direction: column;
    }
    .get-your-check-code{
        align-self: center;
        width: 60%;
    }
    .check-code-input-container{
        align-self: center;
        width: 60%;
       // padding: 0 0.5rem
    }
}