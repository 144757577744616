#how-works-container {
  .how-works-content {
    font-size: 14px;
    padding: 1rem;
    margin-bottom: 10rem;

    .how-works-heading {
      display: flex;
      justify-content: center;
    }

    .how-works-image {
      width: 90%;
    }

    .how-works-body {
      padding: 1rem;
      padding-top: 0.3rem;

      .how-works-body-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding: 0.75rem;

        .how-works-green-tick {
          align-self: start;
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }
}