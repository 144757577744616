.payment-success-wrapper-main{
    padding: 24px;
    .sub-payment-success-wrapper{
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 675px;    
    }
}
.success-text{
    font-weight: 700;
}