#hidden-div {
    position: absolute;
    bottom: -10000px;
}

#summary-detail {
    padding-bottom: 150px;
    min-height: 100vh;
    .helper-btn-text {
        font-size: 12px;
        color: #616161;
        font-weight: 700;
        margin: 10px auto;
        text-align: center;
    }




    .header-2 {
        margin: 0px 16px !important;
        padding: 16px 10px;
        border-top: 1px solid #EEEEEE;
    }

    .header-icon {
        margin-top: 5px;
    }

    .link {
        color: #3D53F5;
        cursor: pointer;
    }

    .custom-label {
        color: #616161 !important;
        margin-bottom: 4px;
    }

    .custom-label-1-0 {
        color: #000000 !important;
        margin: 0;
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 16px;
    }

    .your-detail {
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-bottom: 10px;
            object-fit: cover;
        }

        .name {
            font-weight: 700 !important;
        }
    }

    .car-detail {
        .declarations-box {
            .declarations-points {
                margin-bottom: 11px;
            }

            img {
                margin-right: 10px;
            }
        }
    }

    .location-box {
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        padding: 2px 16px;

        img {
            margin-right: 16px;
        }

        .inline {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .link {
                color: #4BAA79;
                font-size: 14px !important;
                cursor: pointer;
            }

            p {
                font-size: 16px;
            }
        }

        .blue-text {
            color: #3D53F5 !important;
            font-weight: 400;
        }

        .dark-text {
            color: #000000 !important;
        }
    }

}

@media screen and (max-width: 800px) {
    .signup-summary-btn {
        width: 100% !important;
    }
}