#dashboard-trip-detail {
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;

    .seat-info {
        border-bottom: 1px solid #EEEEEE;
    }

    .text-700 {
        font-weight: 700 !important;
    }

    .primary-color {
        color: #5ED497 !important;
        font-weight: 700 !important;
    }

    .grey-color {
        color: #616161 !important;
        font-weight: 700 !important;
    }

    .trip-person-info {
        border: 2px solid #5ED497;
        border-radius: 20px;
        margin-top: 24px;
        padding: 16px;
    }

    .view {
        cursor: pointer;
        display: inline-block;
    }

    .driver-name {
        font-weight: 700 !important;
    }

    .verified-box {
        padding: 2px 4px;
        width: 63px;
        height: 22px;
        background: #EFFBF5;
        border-radius: 50px;
    }

    .invite-btn {
        min-width: 77px;
        height: 28px;
        background: #EFFBF5;
        border: 1px solid #5ED497;
        border-radius: 50px;
        padding: 4px 8px;
        color: #18A957;

        img {
            margin-right: 5px;
        }
    }

    .text-faded {
        color: #616161;
    }

    .primary2-color {
        color: #11763D;
    }

    .text-600 {
        font-weight: 600 !important;
    }

    .nav-button{
        background-color: #5ED497;
        padding: 8px 12px;
        font-size: 1rem;
        border-radius: 20px;
        align-items: center;
        font-weight: 700;
    }
    .gap-4{
        gap: 4px;
    }

    .min-20{
        min-width: 20%;
    }
    .fit-img{
        max-width: 48px;
        max-height: 48px;
        object-fit: cover;
    }

    .fit-img-sm{
        max-width: 20px;
        height: 20px;
        object-fit: cover;
    }

    .user-detail {
        margin-top: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #EEE;
    }

    .carpool-offers {
        flex-grow: 1;
    }

    .decoration-none {
        text-decoration: none;
    }

    .button-wrapper {
        padding: 2rem 0 3rem;
        background-color: #FFF;
    }

    .carpool-offer-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .confirmed-passenger {
        img{
            width: 48px !important;
            height: 48px !important
        }

    }

    .trip-details-receipt-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.2rem;
        color: #5ED497;
        padding: 1.5rem 1.5rem 0;
        cursor: pointer;
    }
}
.cancel-btn-background{
    background-color: #DF1642 !important;
}
