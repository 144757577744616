#matches {
    position: relative;
    height: 100vh;

    .sub-header {
        padding: 22px 0px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
    }

    .matches-box {
        margin-bottom: 30px !important;
        height: calc(100vh - 29%);
        overflow: auto;
        background-color: #FFF;
    }

    .primary-color {
        font-weight: 700 !important;
        color: #5ED497 !important;
    }

    .click {
        cursor: pointer;
    }

    .trip-person-info {
        min-height: 237px;
        border: 2px solid #616161;
        border-radius: 20px;
        margin-bottom: 24px;
        padding: 16px;
        cursor: pointer;


        .view {
            cursor: pointer;
            display: inline-block;
        }

        .driver-name {
            font-weight: 700 !important;
        }

        .verified-box {
            padding: 2px 4px;
            width: 63px;
            height: 22px;
            background: #EFFBF5;
            border-radius: 50px;
        }

        .invite-btn {
            min-width: 77px;
            height: 28px;
            background: #EEEEEE;
            color: #616161;
            border-radius: 50px;
            padding: 4px 8px;
            // border: 1px solid #5ED497;
            // background: #EFFBF5;
            // color: #18A957;

            img {
                margin-right: 5px;
                display: none;
            }
        }

        .invite-btn-active {
            border: 1px solid #5ED497 !important;
            background: #EFFBF5 !important;
            color: #18A957 !important;

            img {
                display: initial;
            }
        }
    }

    .invited-person {
        border: 2px solid #5ED497 !important;
    }

    .map-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .expand-transition {
        transition: all 0.15s linear;
    }

    .text-faded {
        color: #616161;
    }

    .text-600 {
        font-weight: 600 !important;
    }

    .items-center {
        align-items: center;
    }

    .primary2-color {
        color: #11763D;
    }

    .rounded {
        border-radius: 0.5rem;
    }

    .next-btn-box {
        padding-bottom: 40px;
        padding-top: 20px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid #EEEEEE;
    }
}

.no-match-found {

    //  width:100%;
    .no-match-message {
        width: 100%;
        height: 100px;
        margin: 2px auto;
    }
}

.button-container {
    padding: 24px;
}
