.token-button {
    text-transform: none !important;
    font-weight: 700 !important;
    border-width: 2px !important;
    padding: .5rem 1rem !important;
}

// .card-details-new-wrapper {
//     // padding-bottom: 125px;
// }

.card-details-wrapper {
    display: flex;
    flex-direction: column;
    // max-height: 70vh;
    padding: 0 1.5rem 150px 1.5rem;

    .marketplace-card-next-button-container {
        // position: fixed;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // padding: 0.5rem 1.25rem 1.5rem 1.25rem;
        border-top: 1px solid #c1c1c1;
        // background-color: white;
        padding: 1rem 0 2rem 0;
        
    }

    
}

@media screen and (max-width: 800px) {
    // .card-details-wrapper {
    //     min-height: 72vh;
    //     padding: 1rem 1.5rem 2rem 1.5rem;
    // }
}

.gift-card-discount-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    background-color: #EFFBF5;
    margin: 0rem 0 .5rem;

    .discount-row-left {
        display: flex;
        flex-direction: row;

        p {
            padding: 0.2rem;
        }
    }
}