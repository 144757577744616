.days-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;


  .selected-day {
    background-color: #5ED497;
    border-radius: 16px;
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
    cursor: pointer;
    text-align: center;
    flex: 1;
  }

  .unselected-day {
    background-color: #EEEEEE;
    color: #616161;
    border-radius: 16px;
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
    cursor: pointer;
    text-align: center;
    flex: 1;
  }
}

.w-25 {
  width: 25%;
}

.description {
  padding: 2rem 0;
  font-size: 12px;
}

.title {
  font-size: 1.5rem;
  color: white;
}

.carpool-title {
  font-size: 14px;
  margin: 0.5rem 0;
}

.font-14 {
  font-size: 14px;
}

.pb-150 {
  padding-bottom: 150px;
  min-height: 100vh;
}

@media screen and (min-width: 1200px) {
  .days-container {
    gap: 2rem;
    .selected-day {
      padding: 0.25rem 1.75rem;
    }

    .unselected-day {
      padding: 0.25rem 1.75rem;
    }
  }
}

@media screen and (max-width: 800px) {
  .days-container {
    gap: 1.25rem;
    .selected-day {
      padding: 0.25rem 0.75rem;
    }

    .unselected-day {
      padding: 0.25rem 0.75rem;
    }
  }
  .button-width {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .days-container {
    gap: 0.5rem;
    .selected-day {
      padding: 0.25rem 0.75rem;
    }

    .unselected-day {
      padding: 0.25rem 0.75rem;
    }
  }
}