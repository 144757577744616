.user-mobile-number-input {
  .label-container {
    font-weight: 700;
    padding-bottom: 4px;
  }
  input {
    outline: none;
    border-radius: 4px;
    width: 100%;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    border: 2px solid #e0e0e0;
    font-size: 1rem;
  }
}
.elgibility-check-action {
  .sign-up-button {
    align-items: center;
  }
}

@media screen and (max-width: 800px) {
  .sign-up-button {
    align-items: inherit !important;
  }
}
@media screen and (min-width: 800px) {
  .user-mobile-number-input{
   width: 50%;
   margin: 0 auto;
  }
  .label-container {
    display: flex;
    justify-content: flex-start;
    width: 50%;
  }
}
