#otp-screen {
  input[type="tel"] {
    width: 44px;
    height: 52px;
    text-align: center;
    font-size: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 12px;
    background: #effbf5;
    transition: border-color 0.3s;
    outline: none;
    &:focus {
      border-color: #000; 
    }
  }
  .verify-text{
    .text-resend{
      padding: 0 2px
    }
  }
  .otp-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    background: #d1d5db;
    padding-top: 5px;
    padding-bottom: 2rem;
    position:relative;
    .numeric-keypad {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
    }
    button {
      font-size: 16px;
      height: 46px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background: #fff;
      cursor: pointer;
      transition: background-color 0.3s;
      text-align: center;
      box-shadow: 0px 2px 0 0 rgba(0,0,0,0.1);
      margin: 3px;
      .key-digits{
        font-size: 25px;
        font-weight: 700;
      }
      .key-letters{
        font-size: 10px;
        font-weight: 400;
      }
    }
   .home-indicator{
    position:absolute;
    bottom:0;
    padding:8px;
    .home-indicator-label{
      width:150px;
      background: #000;
      padding:3px;
      border-radius: 6px;
    }
   } 
  }
}
