#vehicle-detail {
    
    .line-through{
        text-decoration-line: line-through;
    }

    .link {
        color: #3D53F5;
        cursor: pointer;
    }

    .car-detail {
        .declarations-box {
            .declarations-points {
                margin-bottom: 11px;
            }
            img {
                margin-right: 10px;
            }
        }
    }

    .location-box {
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        padding: 16px;

        img{
            margin-right: 16px;
        }

        .inline {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .link {
                color: #4BAA79;
                font-size: 14px !important;
                cursor: pointer;
            }

            p {
                font-size: 16px !important;
            }
        }

        .blue-text {
            color: #3D53F5!important;
            font-weight: 400;
        }

        .dark-text {
            color: #000000!important;
        }
    }
}