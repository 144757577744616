#matches-book-seat {
    position: relative;

    .sub-header {
        padding: 22px 0px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
    }

    .matches-box {
        min-height: 73vh;
        overflow: auto;
        background-color: #FFF;
    }

    .primary-color {
        font-weight: 700 !important;
        color: #5ED497 !important;
    }

    .click {
        cursor: pointer;
    }

    .matches-box {
        background-color: #fff;
        min-height: 73vh;
    }

    .trip-person-info {
        border-radius: 20px;
        margin-bottom: 24px;
        // min-height: 237px;
        padding: 16px;

        .view {
            cursor: pointer;
            display: inline-block;
        }

        .driver-name {
            font-weight: 700 !important;
        }

        .verified-box {
            padding: 2px 4px;
            width: 63px;
            height: 22px;
            background: #EFFBF5;
            border-radius: 50px;
        }

        .invite-btn {
            min-width: 69px;
            height: 28px;
            background: #EEEEEE;
            color: #616161;
            border-radius: 50px;
            padding: 0px 6px;

            img {
                margin-right: 5px;
                display: none;
            }
        }

        .invite-btn-active {
            border: 1px solid #5ED497 !important;
            background: #5ED497 !important; 
            color: #171717 !important;
            font-weight: 500;

            img {
                display: initial;
            }
        }
    }

    .invited-person {
        border: 2px solid #5ED497 !important;
    }

    .uninvited-user {
        border: 2px solid #616161 !important;
    }

    .map-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .expand-transition {
        transition: all 0.15s linear;
    }

    .text-faded {
        color: #616161;
    }

    .text-600 {
        font-weight: 600 !important;
    }

    .items-center {
        align-items: center;
    }

    .primary2-color {
        color: #11763D;
    }

    .rounded {
        border-radius: 0.5rem;
    }

    .next-btn-box {
        background-color: #fff;
        border-top: 1px solid #eee;
        bottom: 0;
        padding-bottom: 40px;
        padding-top: 20px;
        position: sticky;
        // top: 100%;
        width: 100%;
    }


    @media screen and (max-width: 800px) {
        .matches-box {
            min-height: 81vh;
        }
    }
}

.seat-unavailable {
    display: flex;
    justify-content: center;
    text-align: center;
}

.book-seat-wrapper {
    min-height: 84vh !important;
    background: #EFFBF5;
}

.not-booked {
    border: 2px solid #E0E0E0 !important;
    background: #E0E0E0 !important;
    font-weight: 500 !important;
    color: #616161 !important;
}

@media screen and (max-width: 800px) {
    .book-seat-wrapper {
        min-height: 100vh;
    }
}
