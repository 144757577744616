#trip-detail {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  .mb-100 {
    margin-bottom: 100px;
  }

  .select-offer-container {
    min-height: 70vh;
    padding-bottom: 100px;
  }

  .seat-info {
    border-bottom: 1px solid #EEEEEE;
  }

  .text-700 {
    font-weight: 700 !important;
  }

  .primary-color {
    color: #5ED497 !important;
    font-weight: 700 !important;
  }

  .grey-color {
    color: #616161 !important;
    font-weight: 700 !important;
  }

  .trip-person-info {
    height: 237px;
    border: 2px solid #5ED497;
    border-radius: 20px;
    margin-top: 24px;
    padding: 16px;
  }

  .view {
    cursor: pointer;
    display: inline-block;
  }

  .driver-name {
    font-weight: 700 !important;
  }

  .verified-box {
    padding: 2px 4px;
    width: 63px;
    height: 22px;
    background: #EFFBF5;
    border-radius: 50px;
  }

  .invite-btn {
    min-width: 77px;
    height: 28px;
    background: #EFFBF5;
    border: 1px solid #5ED497;
    border-radius: 50px;
    padding: 4px 8px;
    color: #18A957;

    img {
      margin-right: 5px;
    }
  }

  .text-faded {
    color: #616161;
  }

  .primary2-color {
    color: #11763D;
  }

  .text-600 {
    font-weight: 600 !important;
  }

  .user-detail {
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
   // border-bottom: 1px solid #EEE;
  }

  .carpool-offers {
    flex-grow: 1;
  }

  .decoration-none {
    text-decoration: none;
  }

  .button-wrapper {
    padding: 2rem 0 3rem;
    background-color: #FFF;
  }

  .carpool-offer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.selected-user-image{
  height: 64px;
  width: 64px;
  object-fit: cover;
  border-radius: 50%;
}

.additional-message {
  padding-bottom: 150px;
}