.text-center {
  text-align: center;
}

.no-wrap {
  text-wrap: nowrap;
}

.capitalize {
  text-transform: capitalize !important;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex !important;
}

// Height
.min-h-100 {
  min-height: 100vh !important;
}

.min-height-100 {
  min-height: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.h-75 {
  height: 75% !important;
}

// Weight
.w-100 {
  height: 100% !important;
}

.btn-width {
  width: 50% !important;
}

.w-75 {
  height: 75% !important;
}

.width-100 {
  width: 100%;
}

.w-fill {
  width: -webkit-fill-available !important;
}

.min-w-100 {
  min-width: 100% !important;
}

// Margin
.m-0 {
  margin: 0 !important;
}

.mx-1 {
  margin: 0rem 0.25rem !important;
}

.mx-2 {
  margin: 0rem 0.5rem !important;
}

.mx-3 {
  margin: 0rem 1rem !important;
}

.mx-4 {
  margin: 0rem 1.5rem !important;
}

.mx-5 {
  margin: 0rem 2rem !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.my-1 {
  margin: 0.25rem 0rem !important;
}

.my-2 {
  margin: 0.5rem 0rem !important;
}

.my-3 {
  margin: 1rem 0rem !important;
}

.my-4 {
  margin: 1.5rem 0rem !important;
}

.my-5 {
  margin: 2rem 0rem !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.btn-margin-responsive {
  margin-bottom: 40px !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 2rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

//font size select button text
.select-button-text,
.text-md {
  font-size: 14px;
}

.bg-white {
  background-color: #fff;
}

.bg-red {
  background-color: #DF1642;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pb-05 {
  padding-bottom: 0.5rem;
}

.pb-1px {
  padding-bottom: 1px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.btn-padding-responsive {
  padding-bottom: 40px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-10vh {
  padding-top: 10vh !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem !important;
}

.p-3 {
  padding: 3rem;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-05 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-05 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-05 {
  padding: 0.5rem;
}

.p-03 {
  padding: 0.3rem;
}

.p-02 {
  padding: 0.2rem;
}

.pt-05 {
  padding-top: 0.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pr-3 {
  padding-right: 3rem;
}

.pr-37 {
  padding-right: 3.7rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-autoo {
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pr-15 {
  padding-right: 1.5rem;
}

.pl-15 {
  padding-left: 1.5rem;
}

.p-15 {
  padding: 1.5rem;
}

.px-15 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-15-rem {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.w-auto {
  width: auto !important;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none;
}

.mb-5rem {
  margin-bottom: 5rem !important;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30% !important;
}

.w-50 {
  width: 50% !important;
}

.w-80 {
  width: 80%;
}

.align-right {
  text-align: right;
}

.d-block {
  display: block;
}

.hidden {
  display: none;
}

.rounded-full {
  border-radius: 50%;
}

.rounded-4 {
  border-radius: 4px !important;
}

.rounded-6 {
  border-radius: 6px;
}

.rounded-2 {
  border-radius: 2px;
}

.rounded-12 {
  border-radius: 12px;
}
.rounded-16 {
  border-radius: 16px;
}

.bordered {
  border: 1px solid #ddd;
}

.bordered-light {
  border: 1px solid #eee;
}

.border-light-gray {
  border: 1px solid #e0e0e0 !important;
}

.bordered-2 {
  border: 2px solid #fff;
}

.outline-2 {
  outline: 2px solid #fff;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.flex-end{
  justify-content: flex-end !important;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-1imp {
  gap: 1rem !important;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}
.gap-4 {
  gap: 0.25rem;
}

.text-sm {
  font-size: 12px !important;
}

.text-sm-3{
  font-size: 16px !important;
}

.text-lg {
  font-size: 18px !important;
  line-height: 31px;
}

.text-lrg {
  font-size: 18px !important;
}

.line-y-md {
  line-height: 1.3rem;
}

.text-xl {
  font-size: 1.5rem !important;
}

.text-2xl {
  font-size: 1.75rem !important;
}

.border-box {
  box-sizing: border-box;
}

.shadow-light-small {
  box-shadow: 0px 1px 3px 0px #e0e0e0 !important;
}

.shadow-light {
  box-shadow: 0px 0px 7px 4px rgba(48, 49, 51, 0.1);
}

.shadow-extra-light {
  box-shadow: 0px 3px 18px 4px rgba(0, 0, 0, 0.05);
}

.bg-white {
  background-color: #fff;
}

.bg-light-blue {
  background-color: #69BFFD33 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.text-700 {
  font-weight: 700;
}

.text-500 {
  font-weight: 500 !important;
}

.text-400 {
  font-weight: 400 !important;
}

.text-white {
  color: #fff !important;
}

.text-dark {
  color: #333 !important;
}

.text-gray {
  color: rgba(97, 97, 97, 1);
}

.text-light-gray {
  color: #9E9E9E !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.bg-black {
  background-color: #171717 !important;
}


.sticky {
  position: sticky !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.text-green {
  color: rgba(17, 118, 61, 1);
}

.max-h-none {
  max-height: none !important;
}

.padding-x {
  padding: 0 16px !important;
}

.bg-f5 {
  background: #f5f5f5 !important;
}

.bg-gray {
  background: #eee !important;
}

.bg-green {
  background-color: #A3DDBC !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.select-none {
  user-select: none !important;
}

.min-130 {
  min-width: 130px;
}

.min-145 {
  min-width: 145px;
}

.text-sm-2 {
  font-size: 14px !important;
}

.btn-text {
  font-size: 1rem !important;
  letter-spacing: 0.03rem !important;
  font-weight: 700 !important;
}

.shadow-light-2 {
  box-shadow: 0px 2px 7px 0px rgba(48, 49, 51, 0.1);
}

.pt-1-pb-0 {
  padding-top: 1rem !important;
  padding-bottom: 0rem !important;
}

.pl-pr-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.green-bordered {
  border: 2px solid #5ed497 !important ;
}

.object-fit-contain{
  object-fit: contain;
}

.object-fit-cover{
  object-fit: cover;
}

.blue-bordered {
  border: 2px solid #69bffd !important ;
}

.green-bordered {
  border: 2px solid #5ed497;
}

.fw-500{
  font-weight: 500;
}

.text-primary {
  color: #5ED497 !important;
}

.color-primary{
  color :  #5ED497  !important;
}

@media screen and (max-width: 800px) {
  .btn-width {
    width: 100% !important;
  }

  .mt-16 {
    margin-top: -16px !important;
  }

  .calc-top {
    top: calc(100vh - 620px);
  }

  .btn-padding-responsive {
    padding-bottom: 80px !important;
  }

  .btn-margin-responsive {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-height: 500px) and (max-width: 700px) {
  .calc-top {
    top: 0 !important;
  }
}

.text-new {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  letter-spacing: 0.03em !important;
  color: #616161 !important;
}
.text-new-sm {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: 0.03em !important;
  color: #616161 !important;
}

.pb-05 {
  padding-bottom: 0.5rem;
}

.pt-025 {
  padding-top: 0.25rem;
}

.color-error {
  color: #df1642 !important;
}

.color-blue {
  color : #3D53F5;
}

.background-error {
  background: "#DF1642";
}

.mb-50 {
  margin-bottom: 50% !important;
}

.transform-1{
  transform: translate(-86px, 10px);
}
